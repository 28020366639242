import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

abstract class BaseController {
  protected baseControl: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.baseControl = axios.create(config);
  }

  updateAuthorizationHeader(token: string): void {
    this.baseControl.defaults.headers.common.Authorization = token;
  }
}

export default BaseController;
