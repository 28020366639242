import React, { lazy } from 'react';
import { Redirect, Route as ReactRoute } from 'react-router-dom';

import { ROUTES } from '@utility/route-navigation';
import Main from 'layouts/main';
import { Route } from 'routing/router';

const Private = (props: Route): JSX.Element => {
  const { view, layout, ...rest } = props;

  // @todo: get the real value for isAuthenticated
  const isAuthenticated = false;

  if (isAuthenticated === false) {
    return <Redirect to={ROUTES.LOGIN_PAGE} />;
  }

  const Layout = layout
    ? lazy(() =>
        import(`../layouts/${layout}`).then((component) => ({
          default: component.default,
        }))
      )
    : Main;

  const View = lazy(() =>
    import(`@containers/${view}`).then((component) => ({
      default: component.default,
    }))
  );

  return (
    <ReactRoute
      {...rest}
      render={(routeProps) => (
        <Layout>
          <View {...routeProps} {...rest} />
        </Layout>
      )}
    />
  );
};

export default Private;
