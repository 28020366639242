import Cookies from 'universal-cookie/es6';

export enum COOKIES {
  ID_TOKEN = 'idToken',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ROLE = 'role',
  USERNAME = 'username',
  OTP_SESSION = 'otp_session',
  ENABLED_MFA = 'enabled_mfa',
  PASSWORD = 'password',
  REDIRECT = 'redirect',
  GROUP_ID = 'group_id',
}

export const ENCRYPT_KEY = '@dViC3R3V0lUt1ON';

/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
const cookie = new Cookies();

export const setCookie = (
  name: string,
  value: string,
  domain: string,
  expiry?: Date
): void => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
  cookie.set(name, value, {
    path: '/',
    // domain, disabling the domain parameter
    expires: expiry,
    sameSite: 'lax',
  });
};

export const getCookie = (name: string): string => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
  return cookie.get(name) as string;
};

export const deleteCookie = (name: string, domain: string): void => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
  cookie.remove(name, {
    path: '/',
    domain,
  });
};

export const deleteAllCookies = (domain: string): void => {
  Object.values(COOKIES).forEach((name) => deleteCookie(name, domain));
};

export const resetAllCookies = (domain: string): void => {
  Object.values(COOKIES).forEach((name) => setCookie(name, '', domain));
};
