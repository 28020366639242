import { useSelector, TypedUseSelectorHook } from 'react-redux';

import {
  UserActionTypes,
  UserReducerType,
  UserInfoState,
  GET_USER,
  UPDATE_USER_ATTRIBUTES,
  UPDATE_USER_ATTRIBUTES_ERROR,
  SET_PERMANENT_PASSWORD,
  UPDATE_USER_ATTRIBUTES_PENDING,
} from './types';

const initialState: UserInfoState = {
  user: {},
  passwordStatus: {},
  error: null,
  pending: false,
};

export const userTypedSelector: TypedUseSelectorHook<UserReducerType> =
  useSelector;

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserInfoState {
  switch (action.type) {
    case GET_USER:
      return {
        ...initialState,
        user: action.payload,
        error: null,
      };

    case UPDATE_USER_ATTRIBUTES:
      return {
        ...initialState,
        user: {
          ...initialState.user,
          attributes: action.payload,
        },
        pending: false,
        error: null,
      };

    case SET_PERMANENT_PASSWORD:
      return {
        ...initialState,
        passwordStatus: action.payload,
        pending: false,
        error: null,
      };

    case UPDATE_USER_ATTRIBUTES_PENDING:
      return {
        ...state,
        pending: true,
      };

    case UPDATE_USER_ATTRIBUTES_ERROR:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };

    default:
      return state;
  }
}
