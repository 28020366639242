export interface GetUser {
  username: string;
}

export interface UserAttributes {
  username?: string;
  email_verified?: string;
  phone_number_verified?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface UserInfo {
  username?: string;
  create_date?: string;
  update_date?: string;
  enabled?: boolean;
  status?: string;
  preferred_mfa_settings?: string;
  mfa_setting_list?: string[];
  attributes?: UserAttributes;
}

export interface AuthenticationResult {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;
  RefreshToken: string;
  TokenType: string;
}

export interface PasswordSetStatus {
  AuthenticationResult?: AuthenticationResult;
  email?: string;
  phone_number?: string;
  username?: string;
  error?: boolean;
}

export interface UserInfoState {
  user: UserInfo;
  passwordStatus: PasswordSetStatus;
  error?: string | null;
  pending: boolean;
}

export interface UserReducerType {
  user: UserInfoState;
}

export const GET_USER = 'GET_USER';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const UPDATE_USER_ATTRIBUTES_PENDING = 'UPDATE_USER_ATTRIBUTES_PENDING';
export const UPDATE_USER_ATTRIBUTES_ERROR = 'UPDATE_USER_ATTRIBUTES_ERROR';
export const SET_PERMANENT_PASSWORD = 'SET_PERMANENT_PASSWORD';

interface GetUserAction {
  type: typeof GET_USER;
  payload: UserInfo;
}

interface UpdateUserAttributesAction {
  type: typeof UPDATE_USER_ATTRIBUTES;
  payload: UserAttributes;
}

interface UpdateUserAttributesError {
  type: typeof UPDATE_USER_ATTRIBUTES_ERROR;
  payload: string;
}

interface UpdateUserAttributesPending {
  type: typeof UPDATE_USER_ATTRIBUTES_PENDING;
  payload: string;
}

interface SetPermanentPasswordAction {
  type: typeof SET_PERMANENT_PASSWORD;
  payload: PasswordSetStatus;
}

export type UserActionTypes =
  | GetUserAction
  | UpdateUserAttributesAction
  | SetPermanentPasswordAction
  | UpdateUserAttributesPending
  | UpdateUserAttributesError;
