import React, { lazy } from 'react';
import { Route as ReactRoute } from 'react-router-dom';

import Main from 'layouts/main';
import { Route } from 'routing/router';

const Public = (props: Route): JSX.Element => {
  const { view, layout, ...rest } = props;
  const Layout = layout
    ? lazy(() =>
        import(`../layouts/${layout}`).then((component) => ({
          default: component.default,
        }))
      )
    : Main;

  const View = lazy(() =>
    import(`@containers/${view}`).then((component) => ({
      default: component.default,
    }))
  );

  return (
    <ReactRoute
      {...rest}
      render={(routeProps) => (
        <Layout>
          <View {...routeProps} {...rest} />
        </Layout>
      )}
    />
  );
};

export default Public;
