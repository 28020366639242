import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { userReducer } from './reducers/user/userReducer';

const rootReducer = combineReducers({
  user: userReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore(): Store {
  return createStore(rootReducer, applyMiddleware(...[thunkMiddleware]));
}
