// API Endpoint URL
/* eslint-disable import/prefer-default-export */
const getDomain = (): string => {
  switch (String(process.env.REACT_APP_ENV)) {
    case 'stage':
    case 'dev':
      return 'https://staging-access.advicerevolution.com.au';
    case 'prod':
      return 'https://app-access.advicerevolution.com.au';
    case 'viridian':
      return 'https://viridian-access.advicerevolution.com.au';
    default:
      return 'localhost';
  }
};

// The API Endpoint for Cognito
const getAPIEndpointUrl = (): string => {
  switch (String(process.env.REACT_APP_ENV)) {
    case 'stage':
    case 'dev':
      return 'https://0ttlwaz65b.execute-api.ap-southeast-2.amazonaws.com/Prod';
    case 'prod':
      return 'https://a2003vc15e.execute-api.ap-southeast-2.amazonaws.com/Prod';
    case 'viridian':
      return 'https://yo0xtf01ag.execute-api.ap-southeast-2.amazonaws.com/Prod';
    default:
      return 'https://0ttlwaz65b.execute-api.ap-southeast-2.amazonaws.com/Prod';
  }
};

const getWPEndpointUrl = (base = false): string => {
  switch (String(process.env.REACT_APP_ENV)) {
    case 'stage':
    case 'dev':
      return `https://testing.advicerevolution.com.au${
        !base ? '/wp-json/cognito/v1' : ''
      }`;
    case 'prod':
      return `https://app.advicerevolution.com.au${
        !base ? '/wp-json/cognito/v1' : ''
      }`;
    case 'viridian':
      return `https://viridian.advicerevolution.com.au${
        !base ? '/wp-json/cognito/v1' : ''
      }`;
    default:
      return `https://devtesting2.advicerevolution.com.au${
        !base ? '/wp-json/cognito/v1' : ''
      }`;
  }
};

// Domain
export const SERVICE_DOMAIN = getDomain();
export const DOMAIN =
  window.location.hostname !== 'localhost' ? `.${SERVICE_DOMAIN}` : 'localhost';
// API Endpoint URL
export const API_ENDPOINT_URL = getAPIEndpointUrl();
export const WP_ENDPOINT_URL = getWPEndpointUrl();
export const BASE_WP_URL = getWPEndpointUrl(true);
