import React, { ReactNode } from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    backgroundColor: 'white',
  },
}));

interface MinimalProps {
  children: ReactNode;
}

const Main = (props: MinimalProps): JSX.Element => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <div>{children}</div>
      </Container>
    </div>
  );
};

export default Main;
