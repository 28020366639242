import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';

/* i8n */
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import EN_COMMON from './translations/en/common.json';
import JP_COMMON from './translations/jp/common.json';

import App from './App';

/* eslint-disable @typescript-eslint/no-floating-promises */
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: EN_COMMON,
    },
    jp: {
      common: JP_COMMON,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#0029a9',
    },
    secondary: {
      main: '#808080',
    },
  },
  typography: {
    fontFamily: 'Mont',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
