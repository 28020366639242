import React from 'react';
import { Provider } from 'react-redux';

import Router from 'routing/router';

import 'App.scss';
import configureStore from '@app/store';

const App = (): JSX.Element => {
  const store = configureStore();
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};

export default App;
