import { UserAttributes } from '@app/reducers/user/types';
import { AxiosPromise } from 'axios';
import BaseController from './base-controller';
import { CreateUserParams } from './interface/user-data';

/* eslint-disable @typescript-eslint/no-explicit-any */
class UserController extends BaseController {
  getUser<Data = any>(username: string): AxiosPromise<Data> {
    return this.baseControl.get(`/users/${username}`);
  }

  updateUserAttributes<Data = any>(
    username: string,
    attributes: UserAttributes,
    access_token: string
  ): AxiosPromise<Data> {
    return this.baseControl.put(`/users/${username}`, {
      access_token,
      attributes,
    });
  }

  setPermanentPassword<Data = any>(
    email: string,
    temp_password: string,
    new_password: string
  ): AxiosPromise<Data> {
    return this.baseControl.post(`/users/${email}/permanent-password`, {
      temp_password,
      new_password,
    });
  }

  saveNewUser<T>(params: CreateUserParams): AxiosPromise<T> {
    return this.baseControl.post('/users', params);
  }
}

export default UserController;
