import { AxiosPromise } from 'axios';
import CREDENTIALS from '@utility/credentials';
import BaseController from './base-controller';
import { AuthDetailsParams, OTPStatusParams } from './interface/user-data';
import { VerifyMFAToken } from './interface/session-data';

class WPController extends BaseController {
  authDetails<T>(params: AuthDetailsParams): AxiosPromise<T> {
    return this.baseControl.get('/authDetails', {
      params,
      auth: {
        username: CREDENTIALS.WP_USER,
        password: CREDENTIALS.WP_PASSWORD,
      },
    });
  }

  verifySMSMfaToken<T>(params: VerifyMFAToken): AxiosPromise<T> {
    const { username, otp_code, session } = params;

    return this.baseControl.post(
      '/verifyMFA',
      { session, otp_code },
      {
        params: {
          email: username,
        },
        auth: {
          username: CREDENTIALS.WP_USER,
          password: CREDENTIALS.WP_PASSWORD,
        },
      }
    );
  }

  otpStatus<T>(params: OTPStatusParams): AxiosPromise<T> {
    return this.baseControl.post('/otpStatus', params, {
      auth: {
        username: CREDENTIALS.WP_USER,
        password: CREDENTIALS.WP_PASSWORD,
      },
    });
  }
}

export default WPController;
