import React, { ReactNode } from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/node_modules/@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'fit-content',
    margin: '20px auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60vh',
      minWidth: '40vh',
    },
  },
}));

interface CenteredBoxProps {
  children: ReactNode;
}

const CenteredBox = (props: CenteredBoxProps): JSX.Element => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={4}>
      {children}
    </Paper>
  );
};

export default CenteredBox;
