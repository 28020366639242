import React, { Suspense } from 'react';
import { RouteProps, Router as ReactRouter, Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';

import { reactRouterHistory } from '@utility/route-navigation';
import routes from './routes';
import Private from './private';
import Public from './public';

export interface Route extends RouteProps {
  path: string;
  view: string;
  layout?: string;
  authRequired?: boolean;
}

const Router = (): JSX.Element => {
  return (
    <ReactRouter history={reactRouterHistory}>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          {routes.map((route: Route) => {
            if (route.authRequired) {
              return <Private exact key={route.path} {...route} />;
            }

            return <Public exact key={route.path} {...route} />;
          })}
        </Switch>
      </Suspense>
    </ReactRouter>
  );
};

export default Router;
