import React, { ChangeEvent, useContext, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { COOKIES, getCookie } from '@utility/cookies';
import { RouterNavigation, ROUTES } from '@utility/route-navigation';
import { BASE_WP_URL } from '@utility/urls';

import AppContext from '@app/app-context';
import { LoginChallenge } from '@app/controller/interface/session-data';
import CenteredBox from '@components/centered-box';

export enum AuthType {
  TEXTCODE = 'textCode',
  AUTHAPP = 'authApp',
  DISABLE = 'disable',
}

const MFASettings = (): JSX.Element => {
  const enabledMFA = getCookie(COOKIES.ENABLED_MFA);
  const { t } = useTranslation('common');

  const [authType, setAuthType] = useState(
    enabledMFA ? AuthType.DISABLE : AuthType.TEXTCODE
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const { app } = useContext(AppContext);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAuthType(event.target.value as AuthType);
  };

  const disableHandler = (logout = true) => {
    setIsUpdating(true);
    if (enabledMFA === LoginChallenge.SOFTWARE_TOKEN_MFA) {
      app
        .removeSoftwareMfa()
        .then(() => {
          if (logout) {
            setIsUpdating(false);

            if (window.top && window.top !== window.self) {
              window.top.location.href = BASE_WP_URL;
              app.cleanup();
            } else {
              app.logout();
            }
          }
        })
        .catch((error) => {
          console.log('removeSoftwareMfa error', error);
        });
    } else if (enabledMFA === LoginChallenge.SMS_TOKEN_MFA) {
      app
        .removeSMSMfa()
        .then(() => {
          if (logout) {
            setIsUpdating(false);

            if (window.top && window.top !== window.self) {
              window.top.location.href = BASE_WP_URL;
              app.cleanup();
            } else {
              app.logout();
            }
          }
        })
        .catch((error) => {
          console.log('removeSMSMfa error', error);
        });
    }
  };

  const textCodeHandler = () => {
    RouterNavigation.navigateTo(ROUTES.VERIFY_PHONE);
  };

  const authAppHandler = () => {
    setIsUpdating(true);
    disableHandler(false);
    app
      .addSoftwareMfa()
      .then((secretcode: string) => {
        setIsUpdating(false);
        RouterNavigation.navigateTo(
          ROUTES.SETUP_AUTHAPP.replace(/:secretcode/g, secretcode)
        );
      })
      .catch((error) => {
        console.log('addSoftwareMfa error', error);
        setIsUpdating(false);
      });
  };

  const mfaTypeHandler: { [key: string]: () => void } = {
    textCode: textCodeHandler,
    authApp: authAppHandler,
    disable: disableHandler,
  };

  const handleUpdateClick = () => {
    mfaTypeHandler[authType]();
  };

  return (
    <CenteredBox>
      <FormControl component='fieldset'>
        <Grid
          container
          direction='column'
          justifyContent='space-evenly'
          alignItems='flex-start'
          spacing={3}
        >
          <Grid item>
            <Typography variant='h6' component='h6'>
              {t('mfa.mfa')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2' component='p'>
              {t('mfa.mfa-desc')}
            </Typography>
          </Grid>
          <Grid item className='radio-indent'>
            <RadioGroup
              aria-label='MFAType'
              name='MFAType'
              value={authType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={AuthType.TEXTCODE}
                control={<Radio color='primary' />}
                label={t('mfa.textMsgCode')}
                disabled={enabledMFA === LoginChallenge.SMS_TOKEN_MFA}
              />
            </RadioGroup>
          </Grid>

          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='flex-end'
            spacing={1}
          >
            <Grid item>
              <Button variant='outlined' className='uppercase'>
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdateClick}
                style={{ minWidth: '100px' }}
                className='uppercase'
              >
                {isUpdating ? (
                  <CircularProgress color='secondary' size='24px' />
                ) : (
                  t('common.update')
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </CenteredBox>
  );
};

export default MFASettings;
