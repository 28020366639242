import { AxiosPromise } from 'axios';
import {
  AccessTokenParams,
  LoginParams,
  NewPasswordRequiredParams,
  RefreshTokenParams,
  VerifyMFAParams,
  VerifyMFAToken,
} from './interface/session-data';
import BaseController from './base-controller';

class SessionController extends BaseController {
  login<T>(parameters: LoginParams): AxiosPromise<T> {
    return this.baseControl.post('/login', parameters);
  }

  newPassword<Data = unknown>(
    parameters: NewPasswordRequiredParams
  ): AxiosPromise<Data> {
    return this.baseControl.post('/new-password', parameters);
  }

  refreshToken<Data = unknown>(
    parameters: RefreshTokenParams
  ): AxiosPromise<Data> {
    const { username, refresh_token } = parameters;
    return this.baseControl.post(`/${username}/refresh_token`, {
      refresh_token,
    });
  }

  addSoftwareMfa<Data = unknown>(
    parameters: AccessTokenParams
  ): AxiosPromise<Data> {
    const { username, access_token } = parameters;
    return this.baseControl.post(`/users/${username || ''}/software_mfa`, {
      access_token,
    });
  }

  verifySoftwareMfa<Data = unknown>(
    parameters: VerifyMFAParams
  ): AxiosPromise<Data> {
    const { username, access_token, otp_code } = parameters;
    return this.baseControl.post(`/users/${username}/software_mfa/verify`, {
      access_token,
      otp_code,
    });
  }

  removeSoftwareMfa<Data = unknown>(
    parameters: AccessTokenParams
  ): AxiosPromise<Data> {
    const { username, access_token } = parameters;
    return this.baseControl.delete(`/users/${username}/software_mfa`, {
      data: {
        access_token,
      },
    });
  }

  verifySoftwareMfaToken<Data = unknown>(
    parameters: VerifyMFAToken
  ): AxiosPromise<Data> {
    const { username, otp_code, session } = parameters;
    return this.baseControl.post(
      `/users/${username}/software_mfa/token/verify`,
      {
        session,
        otp_code,
      }
    );
  }

  addSMSMfa<Data = unknown>(parameters: AccessTokenParams): AxiosPromise<Data> {
    const { username, access_token } = parameters;
    return this.baseControl.post(`/users/${username || ''}/sms_mfa`, {
      access_token,
    });
  }

  removeSMSMfa<Data = unknown>(
    parameters: AccessTokenParams
  ): AxiosPromise<Data> {
    const { username, access_token } = parameters;
    return this.baseControl.delete(`/users/${username}/sms_mfa`, {
      data: {
        access_token,
      },
    });
  }

  verifySMSMfaToken<Data = unknown>(
    parameters: VerifyMFAToken
  ): AxiosPromise<Data> {
    const { username, otp_code, session } = parameters;
    return this.baseControl.post(`/users/${username}/sms_mfa/token/verify`, {
      session,
      otp_code,
    });
  }
}

export default SessionController;
