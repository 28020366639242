import { ROUTES } from '@utility/route-navigation';
import { AuthType } from '@containers/cognito/mfa/mfa-settings';

export default [
  /* Public routes */
  { path: ROUTES.LOGIN_PAGE, view: 'cognito/login/login-page' },
  { path: ROUTES.SIGNUP_PAGE, view: 'cognito/signup/signup-page' },
  { path: ROUTES.LOGIN_MFA, view: 'cognito/login/login-mfa-page' },
  { path: ROUTES.SETUP_MFA, view: 'cognito/mfa/mfa-settings' },
  { path: ROUTES.SETUP_AUTHAPP, view: 'cognito/mfa/register-authapp' },
  {
    path: ROUTES.VERIFY_TEXTCODE_MFA,
    type: AuthType.TEXTCODE,
    view: 'cognito/mfa/otp-verification',
    firstTime: true,
  },
  {
    path: ROUTES.VERIFY_AUTHAPP_MFA,
    type: AuthType.AUTHAPP,
    view: 'cognito/mfa/otp-verification',
    firstTime: true,
  },
  {
    path: ROUTES.OTP_TEXTCODE,
    type: AuthType.TEXTCODE,
    view: 'cognito/mfa/otp-verification',
    firstTime: false,
  },
  {
    path: ROUTES.OTP_AUTHAPP,
    type: AuthType.AUTHAPP,
    view: 'cognito/mfa/otp-verification',
    firstTime: false,
  },
  {
    path: ROUTES.NEW_PASSWORD_REQUIRED,
    view: 'cognito/login/set-permanent-password',
  },
  {
    path: ROUTES.VERIFY_PHONE,
    view: 'cognito/mfa/phone-verification',
  },
  { path: ROUTES.VERSIONING_TABLE, view: 'versioning/versioning-table' },
  { path: ROUTES.VERSIONING, view: 'versioning/versioning-page' },
  { path: ROUTES.REVISIONS, view: 'versioning/revisions', layout: 'dashboard' },
  { path: ROUTES.ADVICE_BOARD, view: 'advice-board/advice-board-page' },

  /* Private routes (requires login) */
];
