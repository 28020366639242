import { UserData } from './user-data';

export interface LoginParams {
  username: string;
  password: string;
}

export interface NewPasswordRequiredParams {
  password: string;
  code: string;
}

export interface RefreshTokenParams {
  username: string;
  refresh_token: string;
}

export interface AccessTokenParams {
  username: string;
  access_token: string;
}

export interface VerifyMFAParams extends AccessTokenParams {
  otp_code: string;
}

export interface VerifyMFAToken {
  username: string;
  otp_code: string;
  session?: string;
}

export interface SessionResponse {
  Message: UserData;
}

export interface SecretCode {
  SecretCode: string;
}

export enum LoginChallenge {
  SMS_TOKEN_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}
